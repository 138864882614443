export default {
// table样式
  cellStyle ({ row, column, rowIndex, columnIndex }) {
    return 'text-align: center;'
  },
  rowClass ({ row, rowIndex }) {
    return 'text-align: center;background: #F9FAFC;'
  },
  headClass ({ row, rowIndex }) {
    return 'background: #F9FAFC;'
  }
}
