const website = {
  // 网站标题
  title: '欣奕中台客户管理系统',
  logo: require('../assets/logo.jpg'),
  // 上传地址
  uploaderUrl: ''
}

export default {
  install (Vue) {
    Vue.website = website

    Object.defineProperty(Vue.prototype, 'website', {
      get () {
        return website
      }
    })
  }
}
