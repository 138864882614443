import Layout from '@/page/layout'

export default [
  {
    path: '/sys',
    name: '首页',
    component: Layout,
    meta: {
      icon: 'el-icon-menu',
      auth: true
    },
    redirect: '/sys/echart',
    children: [
      {
        path: '/sys/echart',
        name: '系统首页',
        component: () => import('@/views/sys/echart'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/sys/role',
        name: '账户设置',
        component: () => import('@/views/sys/user'),
        meta: {
          hide: true,
          auth: true
        }
      },
      // {
      //   path: '/sys/system',
      //   name: '系统信息',
      //   component: () => import('@/views/sys/system'),
      //   meta: { hide: true }
      // },
      {
        path: '/sys/loginlog',
        name: '登录日志',
        component: () => import('@/views/sys/login-log'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/sys/digitalSignage',
        name: '数字看板',
        component: () => import('@/views/sys/digital-signage'),
        meta: {
          hide: true,
          auth: true
        }
      }
    ]
  },
  {
    path: '/procedure',
    name: ' 小程序',
    meta: {
      icon: 'el-icon-mobile',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/procedure/userList',
        name: '用户列表',
        component: () => import('@/views/procedure/userList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/procedure/allocation',
        name: '首页配置',
        component: () => import('@/views/procedure/allocation'),
        meta: {
          hide: true,
          auth: true
        }
      },
	  {
		  path: '/procedure/manyiduList',
		  name: '满意度调查',
		  component: () => import('@/views/procedure/manyiduList'),
		  meta: {
			hide: true,
			auth: true
		  }
		},
		{
		  path: '/procedure/serviceEvaluation',
		  name: '服务评价',
		  component: () => import('@/views/procedure/serviceEvaluation'),
		  meta: {
			hide: true,
			auth: true
		  }
		},
		{
		  path: '/procedure/feedbackList',
		  name: '反馈列表',
		  component: () => import('@/views/procedure/feedbackList'),
		  meta: {
			hide: true,
			auth: true
		  }
		},
      {
        path: '/procedure/bannerList',
        name: '轮播管理',
        component: () => import('@/views/procedure/bannerList'),
        meta: {
          hide: true,
          auth: true
        }
      },
		{
		  path: '/procedure/prizeEventList',
		  name: '抽奖活动',
		  component: () => import('@/views/procedure/prizeEventList'),
		  meta: {
			hide: true,
			auth: true
		  }
		},
    {
      path: '/procedure/prizeDetail',
      name: '抽奖详情',
      component: () => import('@/views/procedure/prizeDetail'),
      meta: {
    	hide: false,
    	auth: false
      }
    },
    {
      path: '/procedure/prizeLogList',
      name: '抽奖记录',
      component: () => import('@/views/procedure/prizeLogList'),
      meta: {
    	hide: true,
    	auth: true
      }
    },
    ]
  },
  {
    path: '/serve',
    name: ' 服务',
    meta: { icon: 'el-icon-c-scale-to-original',
    auth: true },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/serve/serveList',
        name: '服务列表',
        component: () => import('@/views/serve/serveList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/serve/serveList/serveDetail',
        name: '客户服务详情',
        component: () => import('@/views/serve/serveDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
	  {
	    path: '/serve/serveContent',
	    name: '核销列表',
	    component: () => import('@/views/serve/serveContent'),
	    meta: {
	      hide: true,
	      auth: true
	    }
	  },
	  {
	    path: '/serve/serviceNursing',
	    name: '护理统计（店）',
	    component: () => import('@/views/serve/serviceNursing'),
	    meta: {
	      hide: true,
	      auth: true
	    }
	  },
   //  {
	  //   path: '/serve/serviceOutpatientNursing',
	  //   name: '护理统计（诊）',
	  //   component: () => import('@/views/serve/serveOutpatientNursing'),
	  //   meta: {
	  //     hide: true,
	  //     auth: true
	  //   }
	  // },
    {
      path: '/serve/OutpatientCare',
      name: '护理统计（诊）',
      component: () => import('@/views/serve/OutpatientCare'),
      meta: {
        hide: true,
        auth: true
      }
    },

    {
      path: '/serve/OutpatientCareDetail',
      name: '查看详情',
      component: () => import('@/views/serve/OutpatientCareDetail'),
      meta: {
        hide: false,
        auth: false
      }
    },
    ]
  },
  {
    path: '/shop',
    name: ' 店铺',
    meta: {
      icon: 'el-icon-s-shop',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/shop/shopList',
        name: '店铺列表',
        component: () => import('@/views/shop/shopList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      // {
      //   path: '/shop/shopSchedule',
      //   name: '店铺时间表',
      //   component: () => import('@/views/shop/shopSchedule'),
      //   meta: {
      //     hide: true,
      //     auth: false
      //   }
      // },
      {
        path: '/shop/addShop',
        name: '添加店铺',
        component: () => import('@/views/shop/addShop'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/shop/storageList',
        name: '店铺仓库',
        component: () => import('@/views/shop/storageList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/shop/regionList',
        name: '店铺大区',
        component: () => import('@/views/shop/regionList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/shop/marketList',
        name: '店铺市场',
        component: () => import('@/views/shop/marketList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/shop/shopDetail',
        name: '店铺详情',
        component: () => import('@/views/shop/shopDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/shop/sellDetail',
        name: '销售详情',
        component: () => import('@/views/shop/sellDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/shop/compileShop',
        name: '编辑店铺',
        component: () => import('@/views/shop/compileShop'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/shop/leadData',
        name: '编辑店长',
        component: () => import('@/views/shop/leadData'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/shop/addCase',
        name: '创建案例',
        component: () => import('@/views/shop/addCase'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/shop/addStorage',
        name: '添加仓库',
        component: () => import('@/views/shop/addStorage'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/shop/addRegion',
        name: '添加大区',
        component: () => import('@/views/shop/addRegion'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/shop/addMarket',
        name: '添加市场',
        component: () => import('@/views/shop/addMarket'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/shop/scarReckoner',
        name: '疤痕计算器',
        component: () => import('@/views/shop/scarReckoner'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/shop/material',
        name: '素材库',
        component: () => import('@/views/shop/material'),
        meta: {
          hide: true,
          auth: true
        }
      },
    ]
  },
  {
    path: '/staff',
    name: ' 员工',
    meta: {
      icon: 'el-icon-user',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/staff/staffList',
        name: '员工列表',
        component: () => import('@/views/staff/staffList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/staff/addStaff',
        name: '添加员工',
        component: () => import('@/views/staff/addStaff'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/staff/staffSchedule',
        name: '员工排班',
        component: () => import('@/views/staff/staffSchedule_table'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/staff/updateStaffSchedule',
        name: '编辑员工排班',
        component: () => import('@/views/staff/updateStaffSchedule'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/staff/BookList',
        name: '预约看板',
        component: () => import('@/views/staff/BookList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/staff/staffDetail',
        name: '员工详情',
        component: () => import('@/views/staff/staffDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
      // {
      //   path: '/staff/agentList',
      //   name: '代理店长',
      //   component: () => import('@/views/staff/agentList'),
      //   meta: {
      //     hide: true,
      //     auth: true
      //   }
      // },
      {
        path: '/staff/NewBookList',
        name: '预约看板(新)',
        component: () => import('@/views/staff/NewBookList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/staff/staffBaoyou',
        name: '员工保有',
        component: () => import('@/views/staff/staffBaoyou'),
        meta: {
          hide: true,
          auth: true
        }
      }
    ]
  },
  {
    path: '/client',
    name: ' 客户',
    meta: {
      icon: 'el-icon-s-custom',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/client/clientList',
        name: '客户列表',
        component: () => import('@/views/client/clientList'),
        meta: {
          hide: true,
          auth: true
        }
      },{
        path: '/client/shiftShop',
        name: '转店记录',
        component: () => import('@/views/client/shiftShop'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/enteringList',
        name: '进店列表',
        component: () => import('@/views/client/enteringList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/memberClient',
        name: '会员顾客',
        component: () => import('@/views/client/memberClient'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/baoyouClient',
        name: '保有顾客',
        component: () => import('@/views/client/baoyouClient'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/alarmClient',
        name: '流失预警',
        component: () => import('@/views/client/alarmClient'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/backClient',
        name: '回流顾客',
        component: () => import('@/views/client/backClient'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/loseClient',
        name: '流失顾客',
        component: () => import('@/views/client/loseClient'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/AtypeList',
        name: 'A客统计',
        component: () => import('@/views/client/AtypeList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/point',
        name: '客户积分',
        component: () => import('@/views/client/point'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/pointDetail',
        name: '积分详情',
        component: () => import('@/views/client/pointDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/client/pointGoodsBuyLog',
        name: '积分兑换',
        component: () => import('@/views/client/pointGoodsBuyLog'),
        meta: {
          hide: true,
          auth: true
        }
      },
	  {
		path: '/client/lostCase',
		name: '流失优案',
		component: () => import('@/views/client/lostCase'),
		meta: {
			hide: false,
			auth: true
		}
	  },
      {
        path: '/client/timingVisit',
        name: '定时回访',
        component: () => import('@/views/client/timingVisit'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/client/answerVisit',
        name: '客访记录',
        component: () => import('@/views/client/answerVisit'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/auditList',
        name: '审核列表',
        component: () => import('@/views/client/auditList'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/client/memberRank',
        name: '会员等级',
        component: () => import('@/views/client/memberRank'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/client/clientList/clientDetail',
        name: '客户详情',
        component: () => import('@/views/client/clientDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/client/clientList/addClient',
        name: '客户信息',
        component: () => import('@/views/client/addClient'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/client/clientList/addOrder',
        name: '创建订单',
        component: () => import('@/views/client/addOrder'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/client/clientList/addChangeMoney',
        name: '添加收款订单',
        component: () => import('@/views/client/addChangeMoney'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/client/clientList/addRecedeMoney',
        name: '添加退款订单',
        component: () => import('@/views/client/addRecedeMoney'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/client/clientList/addRecedeGoods',
        name: '添加退货订单',
        component: () => import('@/views/client/addRecedeGoods'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/client/addRank',
        name: '添加等级',
        component: () => import('@/views/client/addRank'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/client/exchangeOrder',
        name: '兑换订单',
        component: () => import('@/views/client/exchangeOrder'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/client/clientList/addHealplan',
        name: '治疗方案',
        component: () => import('@/views/client/addHealplan'),
        meta: {
          hide: false,
          auth: false
        }
      },


    ]
  },
  {
    path: '/againVisit',
    name: ' 客保',
    meta: {
      icon: 'el-icon-postcard',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
		{
		  path: '/againVisit/clienrVisit',
		  name: '新单列表',
		  component: () => import('@/views/againVisit/clienrVisit'),
		  meta: {
		    hide: true,
		    auth: true
		  }
		},

      {
        path: '/againVisit/clientNew',
        name: '回访列表',
        component: () => import('@/views/againVisit/clientNew'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/againVisit/newList',
        name: '客保列表',
        component: () => import('@/views/againVisit/newList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/againVisit/warnList',
        name: '预警列表',
        component: () => import('@/views/againVisit/warnList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/againVisit/blameList',
        name: '追责列表',
        component: () => import('@/views/againVisit/blameList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/againVisit/visitDetail',
        name: '客保详情',
        component: () => import('@/views/againVisit/visitDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },

    ]
  },
  {
    path: '/case',
    name: ' 优案',
    meta: {
      icon: 'el-icon-collection',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/case/caseList',
        name: '案例列表',
        component: () => import('@/views/case/caseList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      // {
      //   path: '/case/addCase',
      //   name: '添加案例',
      //   component: () => import('@/views/case/addCase'),
      //   meta: {
      //     hide: true,
      //     auth: true
      //   }
      // },

      {
        path: '/case/satisfiedCase',
        name: '优/满案例',
        component: () => import('@/views/case/satisfiedCase'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/case/caseLibrary',
        name: '案例库',
        component: () => import('@/views/case/caseLibrary'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/case/caseDetail',
        name: '案例详情',
        component: () => import('@/views/case/caseDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
    ]
  },
  {
    path: '/goods',
    name: ' 商品',
    meta: {
      icon: 'el-icon-goods',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/goods/goodsList',
        name: '商品列表',
        component: () => import('@/views/goods/goodsList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/goods/addGoods',
        name: '添加商品',
        component: () => import('@/views/goods/addGoods'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/goods/classifyList',
        name: '分类列表',
        component: () => import('@/views/goods/classifyList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/goods/addClassify',
        name: '添加分类',
        component: () => import('@/views/goods/addClassify'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/goods/goodsDetail',
        name: '商品详情',
        component: () => import('@/views/goods/goodsDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/goods/pointGoodsList',
        name: '积分商品列表',
        component: () => import('@/views/goods/pointGoodsList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/goods/addPointGoods',
        name: '添加积分商品',
        component: () => import('@/views/goods/addPointGoods'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/goods/pointGoodsDetail',
        name: '积分商品详情',
        component: () => import('@/views/goods/pointGoodsDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/goods/editPointGoods',
        name: '编辑积分商品详情',
        component: () => import('@/views/goods/editPointGoods'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/goods/GoodsRanking',
        name: '品项排名',
        component: () => import('@/views/goods/GoodsRanking'),
        meta: {
          hide: true,
          auth: true
        }
      },
    ]
  },
  {
    path: '/card',
    name: ' 优惠券',
    meta: {
      icon: 'el-icon-s-ticket',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/card/cardRecord',
        name: '核销记录',
        component: () => import('@/views/card/cardRecord'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/card/cardList',
        name: '优惠券列表',
        component: () => import('@/views/card/cardList'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/card/addCard',
        name: '添加优惠券',
        component: () => import('@/views/card/addCard'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/card/issueCard',
        name: '发放优惠券',
        component: () => import('@/views/card/issueCard'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/card/cardDetail',
        name: '优惠券详情',
        component: () => import('@/views/card/cardDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
    ]
  },
  {
    path: '/order',
    name: ' 订单',
    meta: {
      icon: 'el-icon-document-copy',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/order/orderList',
        name: '销售出库单',
        component: () => import('@/views/order/orderList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/order/proceedsList',
        name: '收款单列表',
        component: () => import('@/views/order/proceedsList'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/order/refundList',
        name: '退款单列表',
        component: () => import('@/views/order/refundList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/order/refundGoods',
        name: '退货单列表',
        component: () => import('@/views/order/refundGoods'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/order/policy',
        name: '销售政策',
        component: () => import('@/views/order/policy'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/order/orderDetail',
        name: '销售出库单详情',
        component: () => import('@/views/order/orderDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/order/orderUpdateDetail',
        name: '修改销售订单',
        component: () => import('@/views/order/orderUpdateDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },

      {
        path: '/order/refundDetail',
        name: '退/收单详情',
        component: () => import('@/views/order/refundDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },

	  {
	    path: '/order/refundUpdateDetail',
	    name: '修改订单',
	    component: () => import('@/views/order/refundUpdateDetail'),
	    meta: {
	      hide: false,
	      auth: false
	    }
	  },

      {
        path: '/order/unpaidOrderList',
        name: '待支付列表',
        component: () => import('@/views/order/unpaidOrderList'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/order/turnPerformanceList',
        name: '转诊业绩单',
        component: () => import('@/views/order/turnPerformanceList'),
        meta: {
          hide: true,
          auth: true
        }
      },

    ]
  },
  {
    path: '/statistics',
    name: ' 数据',
    meta: {
      icon: 'el-icon-s-data',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/statistics/enterShop',
        name: '线上进店统计',
        component: () => import('@/views/statistics/enterShop'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/taskSucceed',
        name: '月度业绩报表',
        component: () => import('@/views/statistics/taskSucceed'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/bargain',
        name: '月度进店成交',
        component: () => import('@/views/statistics/bargain'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/bargainQita',
        name: '月度其他进店成交',
        component: () => import('@/views/statistics/bargainQita'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/shopMsg',
        name: '业务分析报表',
        component: () => import('@/views/statistics/shopMsg'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/marketing',
        name: '客服进店报表',
        component: () => import('@/views/statistics/marketing'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/shopMarketing',
        name: '月度门店报表',
        component: () => import('@/views/statistics/shopMarketing'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/marketMonth',
        name: '月度市场报表',
        component: () => import('@/views/statistics/marketMonth'),
        meta: {
          hide: true,
          auth: true
        }
      },

      {
        path: '/statistics/finance',
        name: '销售日报表',
        component: () => import('@/views/statistics/finance'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/fundStatistics',
        name: '资金日报表',
        component: () => import('@/views/statistics/fundStatistics'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/favorable',
        name: '月度优惠明细',
        component: () => import('@/views/statistics/favorable'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/staffResult',
        name: '员工业绩',
        component: () => import('@/views/statistics/staffResult'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/turnIntroduce',
        name: '转介绍明细',
        component: () => import('@/views/statistics/turnIntroduce'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/shopBalance',
        name: '店面余额报表',
        component: () => import('@/views/statistics/shopBalance'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/entranceShop',
        name: '线上进店明细',
        component: () => import('@/views/statistics/entranceShop'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/bargainDetail',
        name: '进店成交详情',
        component: () => import('@/views/statistics/bargainDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/consumeDetail',
        name: '业务分析数据',
        component: () => import('@/views/statistics/consumeDetail'),
        meta: {
          hide: false,
          auth: false
        }
      },

	  {
	    path: '/statistics/performanceDetail',
	    name: '月度业绩明细',
	    component: () => import('@/views/statistics/performanceDetail'),
	    meta: {
	      hide: false,
	      auth: false
	    }
	  },
    {
      path: '/statistics/newsingleDetail',
      name: '月度新补单明细',
      component: () => import('@/views/statistics/newsingleDetail'),
      meta: {
        hide: false,
        auth: false
      }
    },
      {
        path: '/statistics/ybVipClientList',
        name: '预备会员明细',
        component: () => import('@/views/statistics/ybVipClientList'),
        meta: {
          hide: false,
          auth: false
        }
      },
      // 会员数据报表
      {
        path: '/statistics/huiYuanTongJi',
        name: '客户数据分析表',
        component: () => import('@/views/statistics/huiYuanTongJi'),
        meta: {
          hide: true,
          auth: true
        }
      },
      // 品项分析报表
      {
        path: '/statistics/productAnalysis',
        name: '品项分析报表',
        component: () => import('@/views/statistics/productAnalysis'),
        meta: {
          hide: true,
          auth: true
        }
      },
      //品项分析报表明细
      {
        path: '/statistics/productAnalysisType',
        name: '品项分析数据明细',
        component: () => import('@/views/statistics/productAnalysisType'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/yellowVData',
        name: '黄V数据报表',
        component: () => import('@/views/statistics/yellowVData'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/yellowVData',
        name: '黄v客服数据',
        component: () => import('@/views/statistics/yellowVService'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/yellowVPhysician',
        name: '黄v客服护理师数据',
        component: () => import('@/views/statistics/yellowVPhysician'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/statistics/customerAnalyse',
        name: '新客分类统计表',
        component: () => import('@/views/statistics/customerAnalyse'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/statistics/customerTag',
        name: '客户标签',
        component: () => import('@/views/statistics/customerTag'),
        meta: {
          hide: true,
          auth: true
        }
      },
      // {
      //   path: '/statistics/serviceTask',
      //   name: '客服任务统计',
      //   component: () => import('@/views/statistics/serviceTask'),
      //   meta: {
      //     hide: true,
      //     auth: true
      //   }
      // },


    ]
  },
  {
    path: '/permission',
    name: ' 权限',
    meta: {
      icon: 'el-icon-bangzhu',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/permission/role',
        name: '角色管理',
        component: () => import('@/views/permission/role'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/permission/department',
        name: '部门管理',
        component: () => import('@/views/permission/department'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/permission/manage',
        name: '管理员',
        component: () => import('@/views/permission/manage'),
        meta: {
          hide: true,
          auth: true
        }
      },


      {
        path: '/permission/basicSetting',
        name: '基础设置',
        component: () => import('@/views/permission/basicSetting'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/permission/labelList',
        name: '标签设置',
        component: () => import('@/views/permission/labelList'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/permission/role',
        name: '权限设置',
        component: () => import('@/views/permission/role'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/permission/addSetting',
        name: '添加基础设置',
        component: () => import('@/views/permission/addSetting'),
        meta: {
          hide: false,
          auth: false
        }
      },
      {
        path: '/permission/editSetting/:id  ',
        name: '编辑基础设置',
        component: () => import('@/views/permission/addSetting'),
        meta: {
          hide: false,
          auth: false
        },
        props: true
      },
      {
        path: '/permission/pointOrigin',
        name: '积分来源',
        component: () => import('@/views/permission/pointOrigin'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/permission/shortMessage',
        name: '短信',
        component: () => import('@/views/permission/shortMessage'),
        meta: {
          hide: true,
          auth: true
        }
      },
      {
        path: '/permission/shortMessageTpl',
        name: '短信模板',
        component: () => import('@/views/permission/shortMessageTpl'),
        meta: {
          hide: true,
          auth: true
        }
      },

    ]
  },
  /* {
    path: '/question',
    name: ' 问卷',
    meta: {
      icon: 'el-icon-tickets',
      auth: true
    },
    component: Layout,
    redirect: '',
    children: [
      {
        path: '/question/questionList',
        name: '问卷列表',
        component: () => import('@/views/procedure/bannerList'),
        meta: {
          hide: true,
          auth: true
        }
      },

    ]
  }, */


]
