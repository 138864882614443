export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/page/login/index')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/page/error/401')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/page/error/404')
  },
  {
    path: '/',
    name: 'layout'
  }
]
