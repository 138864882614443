import Vue from 'vue'
import router from './router'
import store from './store'

import App from './App'

import ElementUI from 'element-ui'

import website from './config'

import style from './utils/style' // style for elementTable

import checkPermission from './directives/checkPermission'

import '@/styles/index.scss'

import './permission'

import './assets/style/iconfont.css' //使用icon

import echarts from "echarts"; // 引入echarts

Vue.use(checkPermission)

Vue.use(ElementUI)

Vue.use(website)

Vue.config.productionTip = false

Vue.prototype.$style = style

Vue.prototype.$echarts = echarts

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
