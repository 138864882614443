/**
 * 参数统一说明
 *
 * @param {string} name 数据名称
 * @param {*} value setStore 必传参数
 * @param {*} [def] 避免取值出错时影响代码
 * @param {string} [type='session'] 存储方式 local
 * */

const key = 'xy-'

export const setStore = (name, value, type = 'session') => {
  let store = window.sessionStorage

  if (type === 'local') store = window.localStorage

  store.setItem(key + name, JSON.stringify(value))
}

export const getStore = (name, def = null, type = 'session') => {
  let store = window.sessionStorage

  if (type === 'local') store = window.localStorage

  const item = store.getItem(key + name)

  if (item !== null) {
    try {
      return JSON.parse(item)
    } catch (err) {
      return def
    }
  }
  return def
}

export const remove = (name, type = 'session') => {
  let store = window.sessionStorage

  if (type === 'local') store = window.localStorage

  store.removeItem(key + name)
}

export const clear = (type = 'session') => {
  if (type === 'local') {
    window.localStorage.clear()
  } else {
    window.sessionStorage.clear()
  }
}
