import router, { resetRouter } from './router'
import store from './store'

const whiteList = ['login']

router.beforeEach(async (to, from, next) => {
  if (store.getters.authKey && store.getters.sessionId) {
    if (whiteList.includes(to.name)) {
      next({ path: store.getters.menus[0].path || '/' })
    } else {
      if (store.getters.menus && store.getters.menus.length > 0) {
        if (to.path === '/') {
          next({ path: store.getters.menus[0].path || '/' })
        }
        next()
      } else {
        const routes = await store.dispatch('setMenus')

        resetRouter()
        router.addRoutes(routes)
        router.addRoutes([{
          path: '*',
          redirect: '/404'
        }])

        if (from.path === '/login') {
          next({ path: routes[0].path, replace: true })
        } else {
          next({ ...to, replace: true })
        }
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      next()
    } else {
      next({ path: '/login' })
    }
  }
})
