/**
 * menus 可访问菜单数组
 * routes 路由数组
*/
export const authRoutes = function (menus, routes) {
  const result = []
  for (const route of routes) {
    const temp = { ...route }
    if (temp.meta.auth) {
      // 根据名字递归校验路由是否显示
      if (hasRouterName(menus, temp.name)) {
        if (temp.children) {
          temp.children = authRoutes(menus, temp.children)
        }
        result.push(temp)
      }
    } else {
      result.push(temp)
    }
  }
  return result
}

export const flatten = function (arr) {
  return arr.reduce( (result, item) => {
    return result.concat(
      item.children
      ? [item, ...flatten( item.children ) ]
        : item )
  }, [] )
}

function trim (str) {
  return str.replace(/\s+/g, '')
}

function hasRouterName (menus, name) {
  return menus.some(menu => trim(menu.title) === trim(name))
}

