<template>
<el-container class="layout">

  <el-header>
    <v-header></v-header>
  </el-header>

  <el-container>

    <el-aside width="280px">
      <v-aside></v-aside>
    </el-aside>

    <el-container class="container">
      <v-title :title="$route.name"></v-title>

      <el-main>
          <el-scrollbar class="scrollbar">
        <transition name="main" mode="out-in">
            <router-view></router-view>
        </transition>
          </el-scrollbar>
      </el-main>
    </el-container>

  </el-container>

</el-container>

</template>

<script>
import VHeader from './components/v-header'
import VAside from './components/aside'
import VTitle from './components/v-title'

export default {
  name: 'Layout',
  components: {
    VHeader,
    VAside,
    VTitle
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.layout {
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
  .container {
    flex-wrap: wrap;
    align-self: baseline;
  }
}
.el-header{
  line-height: 60px;
  background-color: $theme-color;
}
.el-main {
  width: 100%;
  min-width: 900px;
  height: calc(100vh -  150px);
  padding: 40px;
  margin: 20px;
  background-color: #fff;
  .scrollbar {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
.main-leave-active,
.main-enter-active {
  transition: all .4s;
}

.main-enter {
  opacity: 0;
}

.main-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

</style>
