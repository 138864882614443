import Vue from 'vue'
import VueRouter from 'vue-router'

const push = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return push.call(this, location).catch(err => err)
}

// 静态页面(登录页面,404等等)
import pageRouter from './page'

Vue.use(VueRouter)

const createRouter = function () {
  return new VueRouter ({
    routes: pageRouter
  })
}

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
