import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

const files = require.context('./modules', true, /\.js$/)

const modules = files.keys().reduce((res, path) => {
  const key = path.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = files(path)
  res[key] = value.default
  return res
}, {})

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules
})
