<template>
  <div class='v-header'>

    <router-link tag="div" to="/wel" class="container">
      <img class="logo" :src="website.logo" alt="logo">
      <span class="title">{{ website.title }}</span>
    </router-link>

    <div class="user-menu" @click="handleClickToggle">
      <img
        :src="userInfo.img || website.logo"
        class="user-portrait"
        alt="头像"
      />

      <el-dropdown ref="ELdropdown" trigger='click'>
        <span class="user-name">{{ userInfo.username }}</span>

        <el-dropdown-menu slot="dropdown">
          <!-- <router-link to="/" >
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link> -->
          <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
        </el-dropdown-menu>

      </el-dropdown>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'VHeader',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ELdropdown () {
      return this.$refs.ELdropdown
    }
  },
  created () {
  },
  methods: {
    handleClickToggle () {
      if (this.ELdropdown.visible) {
        this.ELdropdown.hide()
      } else {
        this.ELdropdown.show()
      }
    },
    handleLogout () {
      this.$store.dispatch('logout').then(() => {
        
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.v-header {
  display: flex;
  align-items: center;
  .container {
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo {
      width: 40px;
      height: 40px;
      margin-right: 6px;
      border-radius: 4px;
    }
    .title {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      letter-spacing: 4px;
    }
  }
  .user-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    .user-portrait {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .user-name {
      color: #fff;
      line-height: 30px;
      font-size: 18px;
    }
  }
}
</style>
