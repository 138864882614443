<template>
  <div class="menu">
    <ul class="menu-left" ref="menuLeft">
      <div class="active" :style="transform"></div>
      <router-link
        v-for="menu in menus"
        :key="menu.path"
        tag="li"
        :to="menu.children[0].path"
      >
        <i :class="menu.meta.icon"></i>
        <span>{{ menu.name }}</span>
      </router-link>
    </ul>

    <transition name="menu" mode="out-in">
      <el-scrollbar class="menu-right">   
          <template v-for="menu in menus">
            <ul
              v-show="currentPathParent.includes(menu.path)"
              :key="menu.path"
              class="menu-list"
            >
              <p class="menu-title">{{ menu.name }}</p>

              <template v-for="link in menu.children">
                <router-link
                  v-if="link.meta.hide"
                  :key="link.path"
                  tag="li"
                  :to="link.path"
                >
                  <span v-if="link.name == '定时回访'">. {{ link.name }} {{ customerNum }}</span>
                  <span v-else>. {{ link.name }}</span>
                </router-link>
              </template>
            </ul>
          </template>
      </el-scrollbar>
    </transition>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  timingList
} from "@/api/client";
class Search {
  customer_name_or_telephone = "";
  source_id = "";
  user_name = "";
  yjreturn_visit_time = "";
  yjreturn_visit_time2 = "";
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: 'VAside',
  data () {
    return {
      page: new Page(),
      search: new Search(),
      customerNum: '',
    }
  },
  computed: {
    ...mapGetters(['menus']),
    currentPathParent () {
      return this.$route.path.replace(/(\/\w*)\/\w+$/, '$1')
    },
    transform() {
      const activeIndex = this.menus.findIndex(menu => this.currentPathParent.includes(menu.path))
      return {
        transform: `translateY(${activeIndex * 50}px)`
      }
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(option) {
      timingList({ ...this.search, ...this.page, customer_id: this.clienId }).then(res => {
        this.customerNum = res.data.dataCount>0 ? res.data.dataCount : '';
      });
    },
  }
}
</script>

<style lang='scss' scoped>
.menu {
  width: 100%;
  height: 100%;
  display: flex;

  .menu-left,
  .menu-list {
    margin: 0;
    padding: 0;
  }
  li {
    position: relative;
    width: 100%;
    line-height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    list-style: none;
    .el-icon-menu {
      width: 24px;
    }
  }

  .menu-left {
    width: 108px;
    background-color: #464c5b;
    position: relative;
    .active {
      position: absolute;
      top: 16px;
      right: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent #f3f3f3 transparent transparent;
      z-index: 999;
      transition: transform .2s ease;
    }
    li {
      color: #fff;
      &:hover {
        color: $theme-color;
        background-color: rgba(255, 255, 255, .2);
        transition: background-color .3s ease;
      }
    }
  }

  .menu-right {
    flex: 1;
    background-color: #eaedf1;
    border-right: 1px solid #ddd;
    .menu-title {
      margin: 0;
      padding: 0;
      width: 100%;
      text-align: center;
      line-height: 50px;
      color: #999;
      background-color: #f3f3f3;
      border-bottom: 1px solid #ddd;
      box-sizing: border-box;
    }
    .menu-list {
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 1;
      transition: opacity .1s ease;
      li:hover {
        color: $theme-color;
        background-color: rgba(255, 255, 255, .2);
        transition: background-color .3s ease;
      }
      .router-link-active {
        color: $theme-color;
      }
    }
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .router-link-exact-active {
    color: $theme-color;
  }
  
  .menu-enter-active,
  .menu-leave-active {
    transition: opacity 4s;
  }
  .menu-enter,
  .menu.leave-to {
    opacity: 0;
  }
}
</style>
