import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
import { getStore } from '@/utils/store'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
  headers: {
    Platform: 'pc',
  }
  // 允许保存cookie
  // withCredentials: true
})

let msgInstance = null

instance.interceptors.request.use(
  config => {
    if (getStore('authKey')) {
      config.headers['Auth-Key'] = getStore('authKey')
      config.headers['Session-Id'] = getStore('sessionId')
    }
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => {
    const res = response.data

    if (res.code === 401 && router.history.current.path !== '/401') {
      router.push('/401')
    }

    // 获取验证码接口成功返回字段为 1
    if ( (res.status && res.status !== 1) || res.code !== 200 ) {
      if (msgInstance === null) {
        msgInstance = Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 3 * 1000,
          onClose: function () {
            msgInstance = null
          }
        })
      }
      return Promise.reject(res.message)
    }
    return res
  },
  error => Promise.reject(error)
)

export default instance
