import { setStore, getStore } from '@/utils/store'
import viewsRouter from '@/router/views'
import { flatten, authRoutes } from '@/router/authRoutes'
import { login } from '@/api/user'

const filterRoutes = function (authMenus) {
  const hasAdmin = authMenus.includes('*')
  if (hasAdmin) return viewsRouter

  return authRoutes(flatten(authMenus), viewsRouter)
}

const user = {
  state: {
    authKey: getStore('authKey') || '',
    sessionId: getStore('sessionId') || '',
    userInfo: getStore('userInfo') || {},
    roles: getStore('roles') || [],
    menus: [],
    prompt: 0,
  },
  mutations: {
    SET_AUTH_KEY: (state, authKey) => {
      state.authKey = authKey
      setStore('authKey', authKey)
    },
    SET_SESSION_ID: (state, sessionId) => {
      state.sessionId = sessionId
      setStore('sessionId', sessionId)
    },
    SET_USERINFO: (state, userinfo) => {
      state.userInfo = userinfo
      setStore('userInfo', userinfo)
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
      setStore('menus', menus)
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
      setStore('roles', roles)
    },
    SET_PRROMPT: (state, prompt) => {
      state.prompt = prompt
      setStore('prompt', prompt)
    }
  },
  actions: {
    loginByToken ({ commit }, params) {
      return new Promise((resolve, reject) => {
        login(params)
          .then(res => {
            commit('SET_AUTH_KEY', res.auth_key)
            commit('SET_SESSION_ID', res.session_id)
            commit('SET_USERINFO', res.userInfo)
            commit('SET_ROLES', res.menusList)
            commit('SET_PRROMPT', 0)
            
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout ({ commit }) {
      return new Promise(resolve => {
        commit('SET_AUTH_KEY', '')
        commit('SET_SESSION_ID', '')
        commit('SET_USERINFO', {})
        commit('SET_ROLES', [])
        commit('SET_MENUS', [])
        commit('SET_PRROMPT', 0)
        resolve()
      })
    },
    setMenus ({ commit }) {
      return new Promise(resolve => {
        const menus = filterRoutes(getStore('roles'))
        commit('SET_MENUS', menus)
        resolve(menus)
      })
    }
  }
}

export default user
