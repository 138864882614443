import store from '@/store'
const roles = store.getters && store.getters.roles


export default {
  install(Vue) {  
    Vue.directive('check', {
      inserted (el, binding, vnode) {
        const { value } = binding
    
        if (value && Array.isArray(value)) {
          console.log(roles)
          const hasPermission = roles.some(role => value.includes(role))

          if (!hasPermission) {
            el.parentNode && el.parentNode.removeChild(el)
          }
        }
      }
    })
  }
}